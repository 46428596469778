<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1" v-if="isDisplay">
      <div style="width:100%;height: 50px; background-color:#D5932A;padding: 15px;color: #fff; ">系统消息</div>
      <div style="margin-top: 20px;">
        <el-table class="mb-20" :data="certifiedLogs" row-key="id" border empty-text="暂无信息.">
          <el-table-column prop="name" label="发信人" width="120" />
          <el-table-column prop="title" label="内容" min-width="400" />
          <el-table-column prop="ctime" label="时间" min-width="200" />
          <el-table-column  label="操作" width="80" align="center">
            <template #default="scope">
              <el-button type="danger" @click="handleDel(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="flex-1" v-else>
      <div style="width:100%;height: 50px; background-color:#D5932A;padding: 15px;"><span @click="goBack()" style="color: #25657E;cursor: pointer">系统消息</span><span style="color: #fff;padding-left: 5px;">> 胸痛中心--账号不通过审核通知</span></div>
      <div style="margin-top: 20px;">
        尊敬的用户，您好：<br>

        很高兴通知您，贵单位机构用户注册审核已经通过，我们已经为贵单位开通正式会员权限，建议您登录后进入“会员中心”开展下一步工作：<br>

<!--        1、先点击“认证类型选择”，根据医院实际情况确认认证类型；<br>-->

<!--        2、然后在“自我评分”栏目查看认证评分细则，并可以随时根据建设进度更新自评；<br>-->

        1、在建立相关胸痛认证数据采集制度后，点击“登录数据填报平台”可以用与登录本网站相同的账号和密码登录数据填报平台录入胸痛认证数据；<br>

        2、在“建设材料”栏目可以陆续上传相关胸痛中心建设和认证材料；<br>

<!--        您有任何问题也可以随时拨打我们的热线电话0512-68290030、15950075018或发邮件到webmaster@chinacpc.org 给我们提出宝贵建议或反馈问题，感谢您对胸痛中心建设事业的支持！<br>-->

<!--        胸痛中心<br>-->

        2024/4/22
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "StationMessage",
  data(){
    return{
      isDisplay:true,
      organId:'',
      certifiedLogs:[],
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
    }
    this.searchAction();
  },
  methods:{
    searchAction:function (){

      this.loadGrid()

    },
    loadGrid:function (){
      const self=this;
      let params={organId:self.organId,type:'MAIL'}
      this.$axios.get('/api/customer/cert/list', {params:params})
          .then((res) => {
            if(res.msg.flag == 0){
              if (null === res.biz || res.biz.empty) {

              } else {
                res.biz.forEach(function (item){
                  self.certifiedLogs.push({name:'管理员',title:item.title,ctime:item.ctime})
                  self.$message.success({message: res.msg.message});
                })
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
    handleDel:function (){
      const self=this;
      self.isDisplay=false;
    },
    goBack:function (){
      const self=this;
      self.isDisplay=true;
    }
  }
}
</script>

<style scoped>

</style>